export const mn = {
    // Header
    header: {
        dseabi_llc: 'Ди Эс И Эй Би Ай ХХК',
        irmuun_az_pharmacy: 'Ирмүүн Аз Эмийн сан',
        search_with_dots: 'Хайх...',
        watchlist: 'Хадгалсан бүтээгдэхүүн',
        cart: 'Миний сагс',
        profile: 'Профайл',
        signin: 'Нэвтрэх',
        signup: 'Бүртгүүлэх',
        signout: 'Гарах',
        my_wallet: 'Бонус',
        featured_products: 'Онцлох бүтээгдэхүүн',
        about_us: 'Бидний тухай',
        products: 'Бүтээгдэхүүн',
        blog: 'Мэдээлэл',
        contact: 'Холбогдох',      
        bonus: 'Урамшуулал',        
        videos: 'Видео',
    },
    footer: {
        all_rights_reserved: 'Зохиогчийн эрх хуулиар хамгаалагдсан',
        address: 'Хаяг',
        phone_number: 'Утасны дугаар',
        address_pharmacy: 'ЧД, 2-р хороо, Төмөрчний гудамж буюу 8 шаргын гудамж, 8-р байр, 3 давхарт 300 тоот',
        main_menu: 'Үндсэн цэс',
        categories: 'Бүтээгдэхүүний төрлүүд',
        social_channels: 'Сошиал хаягууд',
        home_page: 'Нүүр хуудас',
    },
    about: {
        mototop: 'Эрүүл чанартай бүтээгдэхүүнийг',
        motobot: 'emhurgelt.mn-ээс захиалаарай.',
        shop_now: 'Эмийн Сан',
        feature1: 'Чанартай бүтээгдэхүүн',
        feature2: 'Хялбар захиалга',
        feature3: 'Шуурхай хүргэлт',
        feature4: 'Урамшууллын оноо',
    },
    contact: {
        email: 'И-Мэйл хаяг',
        facebook: 'Facebook хуудас',
        contact_us: 'Бидэнтэй холбогдох',
        location: 'Бидний байршил'
    },
    product_list: {
        search_products: 'Бүтээгдэхүүн хайх',
        category: 'Ангилал',
        brand: 'Бренд',
        tags: 'Зориулалт',
        price: 'Үнэ',
        total: 'Нийт',
        products: 'бүтээгдэхүүн',
        newest: 'Шинэ нь эхэндээ',
        oldest: 'Хуучин нь эхэндээ',
        lowtohigh: 'Үнэ өсөхөөр',
        hightolow: 'Үнэ буурахаар',
        all: 'Бүгд',
        yes: 'Тийм'
    },
    product_card: {
        watchlist: 'Хадгалах',
        removewatchlist: 'Хадгалснаа болиулах',
        cart: 'Сагсанд нэмэх',
        removecart: 'Сагснаас гаргах',
        more: 'Дэлгэрэнгүй',
        remove: 'Хасах',
        description: 'Тайлбар',
        ingredients: 'Найрлага',
        usage: 'Хэрэглэх заавар',
        storage: 'Хадгалах нөхцөл',
        caution: 'Анхааруулга'
    },
    product_detail: {
        price: 'Үнэ',        
        stock: 'Үлдэгдэл',
        order: 'Захиалах',
        shops: 'Зарагдаж буй салбарууд',
    }
}

export const en = {    
    header: {
        dseabi_llc: 'DSEABI LLC',
        irmuun_az_pharmacy: 'Irmuun Az Pharmacy',
        search_with_dots: 'Search...',
        watchlist: 'Watchlist',
        cart: 'My cart',
        profile: 'Profile',
        signin: 'Log in',
        signup: 'Register',
        signout: 'Sign Out',
        my_wallet: 'Bonus',
        featured_products: 'Featured products',
        about_us: 'About us',
        products: 'Products',
        blog: 'Blog',
        contact: 'Contact',        
        bonus: 'Bonus',        
        videos: 'Videos',
    },
    footer: {        
        all_rights_reserved: 'All Rights Reserved',
        address: 'Address',
        phone_number: 'Phone number',
        address_pharmacy: 'ЧД, 2-р хороо, Төмөрчний гудамж буюу 8 шаргын гудамж, 8-р байр, 3 давхарт 300 тоот',
        main_menu: 'Main menu',
        categories: 'Categories',
        social_channels: 'Social accounts',
        home_page: 'Home page',
    },    
    about: {
        mototop: 'We make healthcar',
        motobot: 'Understandable, Accessible and Affordable.',
        shop_now: 'Shop Now',            
        feature1: 'Healthy products',
        feature2: 'Хялбар захиалга',
        feature3: 'Free Delivery',
        feature4: 'Урамшууллын оноо',    
    },
    contact: {
        email: 'E-mail address',
        facebook: 'Facebook page',
        contact_us: 'Contact us',
        location: 'Our location'
    },
    product_list: {
        search_products: 'Search for products',
        category: 'Category',
        brand: 'Brand',
        tags: 'Tags',
        price: 'Price',
        total: 'Total',
        products: 'products',
        newest: 'Date: New to Old',
        oldest: 'Date: Old to New',
        lowtohigh: 'Price: Low to High',
        hightolow: 'Price: High to Low',
        all: 'All',
        yes: 'Yes'
    },
    product_card: {
        watchlist: 'Add to watchlist',
        removewatchlist: 'Remove from watchlist',
        cart: 'Add to cart',
        removecart: 'Remove from cart',
        more: 'More',
        remove: 'Remove',
        description: 'Description',
        ingredients: 'Ingredients',
        usage: 'Usage',
        storage: 'Storage',
        caution: 'Caution'
    },
    product_detail: {
        price: 'Price',        
        stock: 'Stock',
        order: 'Buy now',
        shops: 'Locations',
    }
}